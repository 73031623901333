import React, { Component } from 'react';
// import Compressor from 'compressorjs';
import logerror from 'app/utils/logger';

class Camera extends Component {

  capture = (e) => {
    this.captureInput.click();
  }

  photoChange = (e) => {
    try {
      var fileInput = e.target.files;
      if(fileInput.length > 0) {
        const file = fileInput[0];
        this.props.processing();
        if(file.type.startsWith('video/')) {
          this.extractVideoThumbnail(file);
        }else{
          this.readImagefile(file);
        }
      }
      else {
        this.props.finish('', '');
      }
    } catch (e) {
      console.log(e);
      logerror(e);
      this.props.finish('', '');
    }
    return true;
  }

  extractVideoThumbnail = (file) => {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    video.src = URL.createObjectURL(file);
    video.currentTime = 1;

    video.onloadeddata = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const thumbnail = canvas.toDataURL('image/jpeg');

      this.props.finish(file, thumbnail);
      URL.revokeObjectURL(video.src);
    }
  }

  readImagefile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.props.finish(file, e.target.result);
    }
    reader.readAsDataURL(file);
  }

  /*compressImage = (file, localimage) => {

    new Compressor(file, {
      quality: 0.6,
      success: this.compressSuccess,
      error(err) {
        console.log(err);
        logerror(err);
        this.props.finish('', '');
      },
    });
  }

  compressSuccess = (result) => {
    var reader = new FileReader();
    reader.onload = function(e) {
      this.props.finish(result, e.target.result);
    }.bind(this);
    reader.readAsDataURL(result);
  }*/

  render() {

    return (
        <div>
          <input
            ref={(captureInput) => {this.captureInput = captureInput }}
            type="file"
            onChange={this.photoChange}
            style={{display:'none'}}/>
        </div>
    );
  }
}

export default Camera;
