import React, { Component } from "react";
import { connect } from "react-redux";
import { getTranslate } from "react-localize-redux";
import "assets/styles/intro.scss";
import { withGeneralContext } from "app/context/withGeneralContext";
import { withThemeContext } from "app/context/withThemeContext";
import IntroIcons from "./IntroIcons";
import { RightArrowIcon } from "app/components/svg/RightArrowIcon";
import { CSSTransition } from "react-transition-group";
import { LeftArrowIcon } from "app/components/svg/LeftArrowIcon";
import { NewTabIcon } from "app/components/svg/NewTabIcon";
import { SmallLanguagesIcon } from "app/components/svg/SmallLanguagesIcon";
import LanguagesMenu from "app/components/controls/languagesMenu/LanguagesMenu";
import PoweredBy from "../../home/components/poweredBy/PoweredBy";

class IntroDetails extends Component {
  state = {
    loaded: false,
    isLanguagesMenuOpened: false,
    languagesMenuAnchor: null,
  };

  componentDidMount() {
    this.setState({ loaded: true });
  }

  openLanguagesMenu = (open, event) => {
    if (event) {
      this.setState({ languagesMenuAnchor: event.target });
    }
    this.setState({ isLanguagesMenuOpened: open });
  };

  changeLanguage = () => {
    this.setState({ isLanguagesMenuOpened: false });
  };

  render() {
    let nameClassName = "default-responsive-title title-font direction";
    let descriptionClassName = this.props.descriptionClassName;
    let subtitleClassName = this.props.subtitleClassName;

    return (
      <>
        {this.props.goBack ? (
          <div className="intro-content-back-arrow" onClick={this.props.goBack}>
            <LeftArrowIcon color={this.props.themeContext.defaultTextColor} />
          </div>
        ) : null}

        <div
            style={{
              // paddingBottom: 10,
            }}
            className="intro-content"
        >
          <div className="intro-content-wrapper">
                  <CSSTransition
                      in={this.state.loaded}
                      timeout={200}
                      classNames="top-bottom"
                  >
                      <div className="wrapper wrapper-header">
                          {this.props.themeContext.logo !== "" && !this.props.hideLogo
                              ? (<div>
                              <a
                                  href={this.props.themeContext.logoUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                              >
                                  <img
                                      className="intro-logo"
                                      src={this.props.themeContext.logo}
                                      alt=""
                                  />
                              </a>
                          </div>)
                              : (<div style={{height: 23}}></div>)}
                          <div className="intro-details-action-icons">
                              {this.props.themeContext.hideLanguages === "false" ||
                              this.props.generalContext.customLanguages !== "" ? (
                                  <div
                                      className="intro-details-action-icon intro-details-action-icon-language"
                                      onClick={(event) => this.openLanguagesMenu(true, event)}
                                  >
                                      <SmallLanguagesIcon
                                          color={this.props.themeContext.defaultTextColor}
                            />
                          </div>
                      ) : null}
                      {!this.props.isFullMode && (
                          <div
                              className="intro-details-action-icon"
                              onClick={this.props.openNewTab}
                          >
                            <NewTabIcon
                                color={this.props.themeContext.defaultTextColor}
                            />
                          </div>
                      )}
                    </div>
                  </div>
                </CSSTransition>
              <div className={"text-content"}>
                <div className="content-text-scrollable" style={{...(this.props.subtitle ? {minHeight: "90px"} : {})}}>
                      <CSSTransition
                          in={this.state.loaded}
                          timeout={500}
                          classNames="right-left-medium"
                      >
                          <div style={{overflow: "hidden", overflowWrap: "break-word"}} className={nameClassName + " wrapper"}>
                              {this.props.name}
                          </div>
                      </CSSTransition>
                    {this.props.subtitle && (
                        <CSSTransition
                            in={this.state.loaded}
                            timeout={500}
                            classNames="right-left-quick"
                        >
                            <div  className={this.props.subtitle && this.props.description || !this.props.name ? subtitleClassName : descriptionClassName}
                                 style={{
                                     ...(!this.props.name ? {marginTop: "0px"} : {}),
                                     overflow: "hidden"
                                 }}>
                                {this.props.subtitle}
                            </div>
                        </CSSTransition>
                    )}
                      {this.props.description && this.props.description !== "" ? (
                          <CSSTransition
                              in={this.state.loaded}
                              timeout={500}
                              classNames="right-left-quick"
                          >
                              <div className={descriptionClassName}
                                    style={{
                                        ...(!this.props.name && !this.props.subtitle ? {marginTop: "0px"} : {}),
                                        overflow: "hidden"
                                    }}>
                                  {this.props.description}
                              </div>
                          </CSSTransition>
                      ) : null}
                  </div>
              </div>
              </div>
              <button
              onClick={this.props.start}
              className="intro-start-button"
              style={{
                backgroundColor: this.props.themeContext.buttonBackgroundColor,
                color: this.props.themeContext.buttonTextColor,
              }}
          >
              <span>
                {this.props.startButtonTitle &&
                this.props.startButtonTitle !== ""
                    ? this.props.startButtonTitle
                    : this.props.translate("getStarted")}
              </span>
            <RightArrowIcon color={this.props.themeContext.buttonTextColor}/>
          </button>
          <div className="start-wrapper">
            <CSSTransition
                in={this.state.loaded}
                timeout={200}
                classNames="bottom-top"
            >
              <IntroIcons
                  color={this.props.themeContext.defaultIconsColor}
                  timetomake={this.props.timetomake}
                  stepsNumber={this.props.stepsNumber}
                  hideTotalStepsNr={this.props.hideTotalStepsNr}
              />
            </CSSTransition>
            <CSSTransition
                in={this.state.loaded}
                timeout={200}
                classNames="bottom-top"
            >
              <PoweredBy
                  placement={"intro"}
                  isVisible={
                    this.props.themeContext.poweredMakeree !== undefined
                        ? this.props.themeContext.poweredMakeree
                        : this.props.guide.data.isPoweredByVisible
                  }
              />
            </CSSTransition>
          </div>
        </div>
        <LanguagesMenu
            isOpened={this.state.isLanguagesMenuOpened}
            downMenu={true}
            openLanguagesMenu={this.openLanguagesMenu}
            anchor={this.state.languagesMenuAnchor}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    guide: state.guide,
    controls: state.controls,
    instructions: state.instructions,
    translate: getTranslate(state.locale),
  };
}

export default connect(mapStateToProps)(
    withGeneralContext(withThemeContext(IntroDetails))
);
