import React, { Component } from 'react';
import { connect } from 'react-redux';
import { uploadPhoto, uploadVideo, clean, processing } from './actions';
import Camera from 'app/components/instructions/helpers/camera/Camera';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withThemeContext } from 'app/context/withThemeContext';
import { withGeneralContext } from 'app/context/withGeneralContext';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import CheckIcon from '@material-ui/icons/Check';
import { Scrollbar } from 'react-scrollbars-custom';
import { isImageFile } from 'app/utils/utils';
import 'assets/styles/takephoto.scss';
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';
import { isMobile } from "react-device-detect";

class TakePhoto extends Component {

  state = {
    email: '',
    image: null,
    text: 'Take a photo',
    localImage: null,
  }

  captureImage = () => {
    this.camera.capture('');
  }

  processCapture = () => {
    this.props.dispatch(processing());
  }

  finishCapture = (image, localImage) => {
    if(image != ''){
      if (isImageFile(image.name)) {
        this.setState({ localImage: localImage });
        this.props.dispatch(uploadPhoto(this.props.generalContext.appName, this.props.generalContext.guideId, image, localImage));
      }else{
        this.setState({ localImage: localImage });
        this.props.dispatch(uploadVideo(this.props.generalContext.appName, this.props.generalContext.guideId, image, localImage));
      }
    }
  }

  closeDialog = () => {
    this.props.dispatch(clean());
  }

  render() {

    return (
      <div style={{
        height: '100%', 
        width: '100%',
        backgroundColor: '#ffffff',
        color: '#000000'
      }}>
        <div style={{ height: 'calc(100% - 70px)' }}>
          <Scrollbar noScrollX>
            <div className='takephoto-container'>
              <div className='takephoto-text default-title title-font'>{this.props.translate('takePhoto')}</div>
              <div className='takephoto-sub-text small-title'>{this.props.translate('takePhotoShareText')}</div>
              <div className='camera-container' onClick={this.captureImage}>
                {
                  (!this.props.takephoto.uploading) ?
                    <div className='camera-area'>
                      {
                        (this.props.takephoto.uploaded) ?
                          <div
                            className='camera-area-image'
                            style={{ backgroundImage: 'url(' + this.state.localImage + ')' }}>
                            <div
                              className='camera-area-overlay'>
                              <CheckIcon fontSize='large' />
                            </div>
                            <div className='share-buttons-container'>
                              {
                                (isMobile) ? 
                                <WhatsappShareButton url={ 'http://usercontent.makeree.com/?projectId=' + this.props.takephoto.data.projectId + '&resourceId=' + this.props.takephoto.data.resourceId} title='Built successfully using Makeree interactive digital guilde'>
                                  <WhatsappIcon size={32} round />
                                </WhatsappShareButton> : null
                              }
                              <FacebookShareButton url={ 'http://usercontent.makeree.com/?projectId=' + this.props.takephoto.data.projectId + '&resourceId=' + this.props.takephoto.data.resourceId} quote='Built successfully using Makeree interactive digital guilde' hashtag='Makeree'>
                                <FacebookIcon size={32} round />
                              </FacebookShareButton>
                            </div>
                          </div> :
                          <div className='camera-area-button'>
                          </div>
                      }
                    </div>
                    :
                    <div className='camera-loader-wrapper'>
                      <CircularProgress
                        className='takephoto-loader'
                        color={'primary'}
                        size={60}
                        thickness={4} />
                    </div>
                }
                <Camera
                  ref={(camera) => { this.camera = camera }}
                  processing={this.processCapture}
                  finish={this.finishCapture} />
              </div>
              <div className='takephoto-terms'>
                <span>{this.props.translate('takePhotoAgree')} </span>
                <a className="link" href={this.props.themeContext.termsUrl} target="_blank" rel="noopener noreferrer">
                  {this.props.translate('termsAndConditions')}
                </a>
              </div>
            </div>
          </Scrollbar>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    takephoto: state.takephoto,
    instructions: state.instructions,
    translate: getTranslate(state.locale),
    currentLanguage: getActiveLanguage(state.locale).code
  };
}

function matchDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps, matchDispatchToProps)
  (withGeneralContext(
    withThemeContext(
      (TakePhoto))));
