import React, { Component } from 'react';
import 'assets/styles/step.scss';
import { withThemeContext } from 'app/context/withThemeContext';
import SplitStepDecision from './split/SplitStepDecision';
import SplitStepImageDecision from './split/SplitStepImageDecision';
import StepContentLogo from './StepContentLogo';
import { CSSTransition } from 'react-transition-group';
import { Scrollbar } from 'react-scrollbars-custom';

class StepSplit extends Component {

  state = {
    imageDecisions: false
  }

  componentDidMount() {
    var imageDecisions = true;
    this.props.data.data.forEach(item => {
      if (item.imageUrl === '') {
        imageDecisions = false;
      }
    });
    this.setState({ imageDecisions: imageDecisions });
  }

  render() {

    return (
      <div
        className='step'
        style={(this.props.imageUrl && this.props.imageUrl !== '') ?
          {
            backgroundImage: "url(" + this.props.imageUrl + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            color: this.props.themeContext.splitDecisionTextColor
          } :
          {
            backgroundColor: this.props.themeContext.splitDecisionBackgroundColor,
            color: this.props.themeContext.splitDecisionTextColor,
          }}>
        <div style={{ height: 'calc(100% - 70px)' }}>

            <StepContentLogo />
            <div style={{ width: '100%', height: '100%' }}>
                <Scrollbar noScrollX>
              <div
                className={`split-step-wrapper ${this.props.data.text ? 
                                                (this.state.rtl ? this.props.data.text.toLowerCase().replace(/\s+/g, "").replace(/[.,\/#?!$%\^&\*;:{}=\-_`~()]/g,"").replace(/\s{2,}/g,"") : this.props.data.text.toLowerCase().replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ").replace(/ /ig, '-'))
                                                : ''}`}>
                <CSSTransition in={this.props.isCurrentStep} timeout={500} classNames="right-left-medium">
                  <div className="split-step-title default-title title-font direction-only">
                    {(this.props.data.hideQuestion) ? '' : this.props.data.text}
                  </div>
                </CSSTransition>
                <CSSTransition in={this.props.isCurrentStep} timeout={500} classNames="right-left-quick">
                  {
                    (this.state.imageDecisions) ?
                      <div className="split-step-image-decisions-container">
                        {
                          this.props.data.data.map((item, index) => (
                            <SplitStepImageDecision
                              key={index}
                              question={this.props.data.text}
                              answer={item}
                              fullMode={this.props.fullMode}
                              backgroundColor={this.props.themeContext.splitDecisionButtonBackgroundColor}
                              textColor={this.props.themeContext.splitDecisionButtonTextColor} />
                          ))
                        }
                      </div> :
                      <div className="split-step-decisions-container">
                        {
                          this.props.data.data.map((item, index) => (
                            (item.text !== '') ?
                              <SplitStepDecision
                                key={index}
                                question={this.props.data.text}
                                answer={item}
                                fullMode={this.props.fullMode}
                                backgroundColor={this.props.themeContext.splitDecisionButtonBackgroundColor}
                                textColor={this.props.themeContext.splitDecisionButtonTextColor} /> : null
                          ))
                        }
                      </div>
                  }
                </CSSTransition>
              </div>
                </Scrollbar>
            </div>

        </div>
      </div>
    );
  }
}

export default withThemeContext(StepSplit);
