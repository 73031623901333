import {CLEAN_TAKE_PHOTO_STATE, POST_UPLOAD_PHOTO_INPROGRESS, POST_UPLOAD_PHOTO_FAIL, POST_UPLOAD_PHOTO_SUCCESS, PROCESSING_IMAGE, POST_UPLOAD_VIDEO_INPROGRESS, POST_UPLOAD_VIDEO_FAIL, POST_UPLOAD_VIDEO_SUCCESS} from './actionTypes';

export default function(state = { uploading: false, uploaded: false, error: false }, action) {

  switch(action.type)
  {
      case CLEAN_TAKE_PHOTO_STATE:
        return { ...state, uploading: false, uploaded: false, error: false }
      case POST_UPLOAD_PHOTO_INPROGRESS:
        return { ...state, uploading: true, uploaded: false, error: false }
      case POST_UPLOAD_PHOTO_SUCCESS:
          return { ...state, uploading: false, uploaded: true, error: false, data: action.payload }
      case POST_UPLOAD_PHOTO_FAIL:
          return { ...state, uploading: false, uploaded: false, error: true }
      case POST_UPLOAD_VIDEO_INPROGRESS:
          return { ...state, uploading: true, uploaded: false, error: false }
      case POST_UPLOAD_VIDEO_SUCCESS:
          return { ...state, uploading: false, uploaded: true, error: false, data: action.payload }
      case POST_UPLOAD_VIDEO_FAIL:
          return { ...state, uploading: false, uploaded: false, error: true }
      case PROCESSING_IMAGE:
          return { ...state, uploading: true }
      default:
        return { ...state };
  }
}
