import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getTranslate} from "react-localize-redux";
import {getUnitLabel} from "../../../../../utils/utils";

class MaterialsItem extends Component {

    getQuantityLabel(item) {
        if (item.name && item.name.startsWith('(', 0)) {
            var firstIndex = item.name.indexOf('(');
            var lastIndex = item.name.indexOf(')');
            if (lastIndex > -1) {
                var number = item.name.substring(firstIndex + 1, lastIndex - firstIndex);
                return number;
            }
        }
        return (item.quantity === 0) ? '' : item.quantity;
    };

    getNameLabel(item) {
        if (item.name && item.name.startsWith('(', 0)) {
            var lastIndex = item.name.indexOf(')');
            if (lastIndex > -1) {
                return item.name.substring(lastIndex + 1, item.name.length);
            }
        }
        return item.name;
    };



    render() {

        return (
            <li key={this.props.index} className='direction'>
                <div className='material-content direction'>
                    {
                        (this.props.item.quantity > 0) ?
                            <div className='material-number direction'>
                                {this.getQuantityLabel(this.props.item)}&nbsp;
                                {(getUnitLabel(this.props.item))}
                                {(getUnitLabel(this.props.item)) && (<>&nbsp;</>)}x&nbsp;
                            </div> : null
                    }
                    {this.getNameLabel(this.props.item)}
                </div>
            </li>
        );
    }
}

function matchDispatchToProps(dispatch) {
    return { dispatch };
}

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale)
    };
}

export default connect(mapStateToProps, matchDispatchToProps)(MaterialsItem);
